import React from "react";
import { graphql } from "gatsby";
import { FaDownload } from "react-icons/fa";
import SEO from "../components/seo";
import Layout from "../components/Layout";

import MarkdownContent from "../components/MarkdownContent";

import "../components/materials.css";

export default ({ data, location }) => {
  const { frontmatter } = data.MaterialsPage;
  const { edges } = data.MaterialsFiles;
  return (
    <div>
      <SEO
        title="Materiały"
        pathname={location.pathname}
        keywords="materiały, język grecki, darmowe materiały"
      />
      <Layout>
        <div className="materials">
          <h1>{frontmatter.title}</h1>
          <h2>{frontmatter.description}</h2>
          <ul className="materials__list">
            {edges &&
              edges.map((edge) => (
                <li key={edge.node.id}>
                  <div>
                    <h3>{edge.node.frontmatter.name}</h3>
                    {edge.node.frontmatter.description && (
                      <MarkdownContent
                        content={edge.node.frontmatter.description}
                      />
                    )}
                  </div>
                  <div className="materials__list--icon-container">
                    <a
                      className="materials__list--download"
                      href={
                        edge.node.frontmatter &&
                        edge.node.frontmatter.file &&
                        edge.node.frontmatter.file.publicURL
                      }
                      download
                    >
                      <FaDownload />
                    </a>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query {
    MaterialsPage: markdownRemark(
      frontmatter: { templateKey: { eq: "materials-page" } }
    ) {
      frontmatter {
        title
        description
      }
    }
    MaterialsFiles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "material-file" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            description
            file {
              publicURL
            }
          }
        }
      }
    }
  }
`;
